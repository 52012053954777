@import '../../styles/colors_v2.scss';
@import '../../styles/breakpoints.scss';

.OurPromisesWrapper {
  margin-top: 32px;

  @media (min-width: $lg) {
    margin-top: 75px;
  }
}

.OurPromisesTitle__container {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.OurPromisesTitle {
  font-size: 28px;
  font-weight: 700;
  line-height: 110%;
  letter-spacing: -0.56px;

  @media (min-width: $sm) {
    font-size: 43px;
    letter-spacing: -0.7px;
  }
}

.OurPromisesSubTitle {
  color: $white-aluminum;
  font-size: 18px;
  font-weight: 500;
  display: none;
  white-space: nowrap;

  @media (min-width: $sm) {
    display: block;
  }
}

.OurPromisesInfoBlock {
  @media (min-width: $sm) {
    display: flex;
    flex-wrap: wrap;
  }
}

.OurPromisesInfoBlock__items {
  display: flex;
  align-items: flex-start;
  margin-top: 24px;

  &:first-child {
    margin-top: 16px;
  }

  @media (min-width: $sm) {
    flex: 1 0 50%;

    &:first-child {
      margin-top: 24px;
    }
  }
}

.OurPromisesInfoBlock__icon {
  margin-right: 16px;

  img {
    max-width: 20px;
    min-width: 20px;
    min-height: 20px;
  }

  @media (min-width: $sm) {
    img {
      max-width: 24px;
      min-width: 24px;
      min-height: 24px;
    }
  }
}

.OurPromisesInfoBlock__title {
  font-size: 18px;
  font-weight: 600;
  line-height: 130%;

  @media (min-width: $sm) {
    font-size: 24px;
  }
}

.OurPromisesInfoBlock__subTitle {
  letter-spacing: -0.32px;
  line-height: 130%;
  margin-top: 8px;

  @media (min-width: $sm) {
    font-size: 20px;
    line-height: 135%;
  }
}
