@import 'src/styles/colors';
@import 'src/styles/colors_v2';
@import 'src/styles/breakpoints';

.contain {
  box-sizing: border-box;
  width: 100%;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  padding: 20px 0 20px 0;

  @media (min-width: $sm) {
    padding: 29px 0 29px 0;
  }
}

.controlBtn {
  text-align: start;
  box-sizing: border-box;
  width: 100%;
  height: auto;
  border-radius: 0px;
  position: relative;
  padding: 0;

  span {
    font-size: 18px;
    font-weight: 500;
    line-height: 130%;
  }

  @media (min-width: $sm) {
    span {
      font-size: 24px;
      line-height: 120%;
    }
  }
}

.activeBtn {
  padding: 0;
  width: 100%;
}

.inner {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 0;
}

.dropDownBtn {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    fill: $green-pastel;
  }

  @media (max-width: $sm) {
    width: 24px;
    height: 24px;
  }
}

.rotate {
  transform: rotate(45deg);
}

.content {
  font-size: 14px;
  line-height: 150%;

  @media (max-width: $lg) {
    margin-left: 0;
    font-size: 14px;
  }
}
