@import 'src/styles/colors';
@import 'src/styles/breakpoints';

.container {
  padding: 32px;
  border-radius: 8px;
  background: #F5F5F5;

  @media (max-width: $lg - 1) {
    padding: 32px 16px;
  }
}

.form {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 16px;

  @media (max-width: $lg - 1) {
    flex-direction: column;
  }
}

.title {
  font-size: 18px;
  text-align: center;
}

.input {
  max-width: 320px;
  flex-grow: 1;

  @media (max-width: $lg - 1) {
    max-width: 100%;
    width: 100%;
  }
}

.submit {
  max-width: 160px;
  margin-left: 15px;
  height: 50px;

  svg {
    margin-left: 7px;
  }

  @media (max-width: $lg - 1) {
    max-width: 100%;
    margin: 16px 0 0 0;
  }
}
