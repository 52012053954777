$white: #fff;
$white-cover: rgba(255, 255, 255, 0.15);

$black: #000;
$black-transparent: rgba(0, 0, 0, 0.2);

$font-black: #02180f;

$yellow: #f6d743;
$yellow-light: #fce265;
$yellow-pressed: #ebd25b;

$red: #f64747;

$green: #06623b;
$green-medium: #54a181;
$green-pastel: #34C759;
$green-saturated: #098550;
$green-shift: #e7f0ec;
$green-light: #f1f6f4;
$green-pale: #bad4c6;
$green-selected: #d7f0e5;
$green-transparent: rgba(100, 157, 102, 0.1);
$green-opacity: rgba(2, 24, 15, 0.7);
$green-shadow: rgba(6, 98, 59, 0.15);

$gray: rgb(116, 116, 116);
$gray-light: rgba(116, 116, 116, 0.356);
