@import 'src/styles/colors_v2';
@import 'src/styles/breakpoints';
@import 'src/styles/z_index';

.popularCategoriesWrapper {
  margin-top: 75px;
  position: relative;
  z-index: $z-0;
}

.popularCategoriesTitle {
  font-size: 28px;
  font-weight: 700;
  line-height: 110%;
  margin-bottom: 4px;

  @media (min-width: $lg) {
    display: none;
  }
}

.popularCategoriesTitleDesktop {
  font-size: 48px;
  font-weight: 700;
  line-height: 52px;
  display: none;
  margin-bottom: 12px;

  @media (min-width: $lg) {
    display: block;
  }
}

.popularAssortment {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -4px;
  box-sizing: border-box;

  @media (min-width: $lg) {
    margin: 0 -12px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }
}

.popularAssortmentItem {
  flex-grow: 1;
  flex-shrink: 1;
  flex: 45%;
  background: $smoky-white_2;
  border-radius: 16px;
  min-height: 167px;
  height: 100%;
  position: relative;
  margin: 4px;
  width: 30;
  overflow: hidden;

  @media (min-width: $xs) {
    flex: 30%;
  }

  @media (min-width: $lg) {
    min-height: 272px;
    margin: 13px 12px;
  }
}

.popularAssortmentBread {
  img {
    width: 100%;
  }
}

@media (min-width: $lg) {
  .popularAssortmentMilk {
    grid-area: 1 / 1 / 2 / 2;
    max-width: 272px;
    max-height: 272px;
  }

  .popularAssortmentMeat {
    grid-area: 1 / 2 / 2 / 3;
    max-width: 272px;
    max-height: 272px;
  }

  .popularAssortmentVegetables {
    grid-area: 1 / 3 / 2 / 5;
    max-height: 272px;

    img {
      width: 100%;
      max-width: 568px;
    }
  }

  .popularAssortmentBread {
    grid-area: 2 / 1 / 4 / 3;
    max-height: 568px;

    .popularAssortmentSubTitle {
      font-size: 48px;
    }
    .categorySubTitle {
      font-size: 32px;
    }

    img {
      max-width: 568px;
      width: 100%;
    }

    @media (max-width: $xs) {
      img {
        max-width: 188px;
        width: 100%;
      }
    }
  }

  .popularAssortmentCheese {
    grid-area: 2 / 3 / 3 / 4;
    max-width: 272px;
    max-height: 272px;
  }

  .popularAssortmentSausages {
    grid-area: 2 / 4 / 3 / 5;
    max-width: 272px;
    max-height: 272px;
    margin-top: 4px;
  }

  .popularCatalogDesktop {
    grid-area: 3 / 3 / 4 / 5;
    max-width: 568px;
    max-height: 272px;
    margin: 12px 12px;
    font-size: 48px;
  }
}

.popularAssortmentSubTitle {
  position: relative;
  z-index: 2;
  color: $black;
  padding: 16px 0 0 12px;
  font-size: 18px;
  font-weight: 700;
  line-height: 110%;

  @media (min-width: $lg) {
    font-size: 32px;
  }
}

.popularAssortmentBackground {
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 16px;
}

.popularCatalogContainer {
  flex-shrink: 0;
  flex-grow: 1;
  flex: 100%;
  background: $dark-bluishGreen;
  border-radius: 16px;
  position: relative;
  min-height: 100px;

  &:hover img {
    transition: transform 0.4s ease-in-out;
  }

  &:not(hover) img {
    transition: transform 0.4s ease-in-out;
  }
}

.popularCatalogSubTitle {
  line-height: 130%;
  font-weight: 700;
  font-size: 24px;
  color: $white;
  margin: 16px 0 0 12px;

  @media (min-width: $lg) {
    font-size: 48px;
  }
}

.CatalogBackground {
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 0 16px 16px 0;
}

.catalogCategoryTitle {
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;

  @media (min-width: $sm) {
    display: none;
  }
}
