@import 'src/styles/colors_v2';
@import 'src/styles/breakpoints';
@import 'src/styles/z_index';

.PromotionNewsBlock {
  color: $white;
  position: relative;
  z-index: $z-0;

  :global {
    .swiper-pagination-bullets {
      display: flex;
      max-width: 50%;
      align-items: center;
      margin: 0 0 20px 32px;

      @media (max-width: $sm) {
        display: none;
      }
    }

    .swiper-pagination-bullet {
      width: 12px;
      height: 12px;
      background-color: $white;
    }

    .swiper-pagination-bullet-active {
      width: 12px;
      height: 12px;
      background-color: $white;
    }

    .swiper-button-prev {
      border-radius: 106px;
      background-color: $white;
      left: var(--swiper-navigation-sides-offset);
      padding: 0 8.5px;
      right: 50px;
      margin-right: 10px;
      top: 82%;

      @media (min-width: $sm) {
        top: 85%;
        right: 70px;
        padding: 4px 12.5px;
        margin-right: 5px;
      }
    }

    .swiper-button-prev::after {
      color: $black;
      font-size: 12px;
      font-weight: bold;
    }

    .swiper-button-next {
      top: 82%;
      padding: 0 8.5px;
      border-radius: 106px;
      background-color: $white;
      left: var(--swiper-navigation-sides-offset);

      @media (min-width: $sm) {
        padding: 4px 12.5px;
        top: 85%;
      }
    }

    .swiper-button-next::after {
      color: $black;
      font-size: 12px;
      font-weight: bold;
    }
  }
}

.NewsBlock {
  width: 100%;
  border-radius: 24px;
  background-color: $dark-bluishGreen;
  position: relative;
  z-index: 10;

  .imgFreshVegetables {
    position: absolute;
    bottom: 0;
    right: 0;
    border-bottom-right-radius: 24px;
    z-index: 0;
  }

  .imgIllustration {
    position: absolute;
    bottom: 30%;
    right: 90px;
  }

  .navigationButtons {
    position: absolute;
    bottom: 5%;
    right: 2.5%;
    z-index: 10;
    display: flex;

    .mainBannerButtonPrev {
      background-image: url('../../../public/icons2/arrow-prev.svg');
    }

    .mainBannerButtonNext {
      background-image: url('../../../public/icons2/arrow-next.svg');

      margin-left: 8px;
    }

    img {
    }
  }

  @media (min-width: $sm) {
    display: none;
  }
}

.NewsText {
  text-align: left;
  box-sizing: border-box;
  padding: 16px 0 96px 16px;
  max-width: 300px;
  font-size: 24px;
  letter-spacing: -0.4px;
  line-height: 22px;
}

.PromotionNewsBottomBlock {
  margin-top: 8px;
}

.NewsBottomBlock {
  overflow-x: scroll;
  display: flex;

  @media (min-width: $sm) {
    display: none;
  }
}

.PromotionTitle {
  padding: 12px 0 4px 12px;
  max-width: 143px;
  font-size: 18px;
  line-height: 20px;
}

.PromotionPromoCode {
}

.PromotionText {
  padding: 12px 0 47px 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
}

.PromocodeText {
  background-image: url('../../../public/icons2/file_icon.svg');
  background-repeat: no-repeat;
  background-position: right 5px center;
  background-color: $white;
  color: $dark-bluishGreen;
  padding: 3px 20px 3px 8px;
  border-radius: 6px;
  border: 1px dashed $intense-redOrange;
  margin: 90px 5px 0 0;
}

.NewsBannersDesktop {
  color: $white;
}

.MainBannersDesktop {
  background-color: $dark-bluishGreen;
  border-radius: 24px;
  background-position: right 0 bottom 0;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;
  background-size: auto 190px;
  max-height: 200px;
  min-height: 190px;

  .mainBunnerAplleillustration {
    position: absolute;
    bottom: 23%;
    right: 330px;
  }

  @media (min-width: $sm) {
    min-height: 335px;
    background-size: auto;
  }
}

.mainBannerInfo {
  max-width: 480px;
  margin: 23px 0 0 16px;

  @media (min-width: $sm) {
    margin: 23px 0 0 32px;
  }
}

.mainBannerTitle {
  padding-top: 16px;
  line-height: normal;
  color: white;
  font-size: 24px;

  @media (min-width: $sm) {
    font-size: 48px;
    padding-top: 24px;
  }
}

.mainBannerText {
  font-size: 48px;
  line-height: 110%;
  letter-spacing: -0.96px;
  padding-bottom: 86px;
}

.mainBannerNavigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px 24px 32px;
}

.mainBannerPagination {
  display: flex;
  align-items: center;

  img {
    margin-right: 8px;
  }

  img:last-child {
    margin-right: 0;
  }
}

.mainBunnerButtonContiner {
  display: flex;
}

.mainBannerButtons {
  .mainBannerButtonPrev {
    background-image: url('../../../public/icons2/arrow-prev.svg');
    margin-right: 5px;
  }

  .mainBannerButtonNext {
    background-image: url('../../../public/icons2/arrow-next.svg');
  }
}

.rightBlockNewsDesktopContainer {
  overflow: auto;

  @media (min-width: $sm) {
  }
}

.rightBlockNewsDesktop {
  display: flex;
  align-items: center;
}

.smallNewsBanner {
  height: 160px;
  margin-right: 8px;
  border-radius: 16px;
  flex-grow: 1;
  min-width: 280px;
  margin: 8px 8px 0 0;

  @media (min-width: $sm) {
    min-width: 0px;
    align-self: stretch;
    flex: 1 0 30%;
  }

  &:last-child {
    margin-right: 0;
  }
}

.firstBunner {
  position: relative;
  background: $intense-redOrange;
  background-image: url('../../../public/headers/apple-shapeDesctop.png');
  background-repeat: no-repeat;
  background-position: right 0 bottom 0;

  .BlockTitle {
    font-weight: 700;
  }

  .promotionPromocode {
    background-color: $white;
    color: $intense-redOrange;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.2px;
    position: absolute;
    bottom: 15px;
    right: 15px;
    padding: 6px 10px;
    border-radius: 8px;
    border: 1px dashed $macaroni-cheese;
  }

  .firstBunnerIllustration {
    position: absolute;
    bottom: 49%;
    right: 3px;
  }
}

.secondBunner {
  background-color: $blue-crayola;
  background-image: url('../../../public/headers/illustration-cherry.png');
  background-repeat: no-repeat;
  background-position: right 0 bottom 0;
}

.thirdBunner {
  background-image: url('../../../public/headers/products-shape.png');
  background-position: right 0 bottom 0;
  background-repeat: no-repeat;
  position: relative;
  background-color: $yellowish-white;

  .BlockTitle {
    color: $black;
  }

  .BlockText {
    position: absolute;
    bottom: 0;
  }

  img {
    position: absolute;
    bottom: 0;
    left: 0;
    border-bottom-left-radius: 8px;
  }
}

.BlockInfo {
  max-width: 191px;
  padding-top: 16px;
  margin-left: 16px;
}

.BlockTitle {
  font-size: 24px;
  font-weight: 500;
  font-feature-settings: 'ss12' on;
  line-height: 24px;
  letter-spacing: 0.24px;
  margin-bottom: 5px;
}

.BlockText {
  font-size: 16px;
}
