@import 'src/styles/colors';
@import 'src/styles/breakpoints';

.page {
  width: 100%;
  position: relative;
  :global {
    .footer {
      margin-top: 46px;
    }
  }
}

.popupMenu {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 4;
  background-color: rgba(0, 0, 0, 0.4);
}

.header {
  position: relative;
  width: 100%;
}

.headerWrap {
  @media (max-width: $sm) {
    margin-top: 24px;
  }
}

.wrapWhite {
  text-align: center;
  color: $font-black;
  box-sizing: border-box;
  position: relative;
  z-index: 0;
}

.headerText {
  font-weight: bold;
  font-size: 38px;
  line-height: 130%;
  text-align: center;
  margin: 0;
}

.searchMain {
  margin: 20px auto;
  width: calc(100% - 222px);

  :global {
    .search-select {
      &__control {
        height: 50px;
      }
    }
  }
}

.trendNow {
  margin: 0 111px;
  font-size: 13px;
  line-height: 12px;
  display: flex;
  align-items: center;
}

.trendNowText {
  font-size: 15px;
  line-height: 14px;
  margin-right: 5px;
}

.headerBtns {
  margin: 0 5px;
  background: $green-pastel;
}

.title {
  margin: 50px 0 36px;
  font-weight: bold;
  font-size: 36px;
  line-height: 1.2;
  text-transform: uppercase;

  @media (max-width: ($lg - 1)) {
    margin: 36px 0 24px;
    font-size: 28px;
  }
}

.carousel {
  display: flex;
  margin: 0 -4px;

  @media (max-width: $sm) {
    margin: 0 -8px;
  }
}

.carouselContainer {
  @media (max-width: 1024px) {
    overflow-x: scroll;
  }

  @media (max-width: $sm) {
    overflow-x: scroll;
  }
}

.ProductCardIndents {
  padding: 0 8px;
  width: 55%;
  box-sizing: border-box;

  @media (max-width: 1024px) {
    min-width: 242px;
    margin: 0 5px;
  }

  @media (max-width: $sm) {
    min-width: 160px;
  }

  .slick-dots button::before {
    width: 10px;
    height: 10px;
    top: 5px;
    left: 5px;
    background: $green-pastel;
    opacity: 0.5;
    border-radius: 50%;
    color: transparent;
    transition: ease 0.3s;
  }

  .slick-dots li.slick-active button::before {
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    opacity: 1;
    background: $green-pastel;
    color: transparent;
    transition: ease 0.3s;
  }

  @media (min-width: $sm) {
    width: 25%;
    padding: 0 4px;
  }
}

.FAQContainer {
  margin-top: 32px;

  @media (min-width: $lg) {
    margin-top: 75px;
  }
}

.FAQTitle {
  font-size: 28px;
  font-weight: 700;
}

.storesCarousel {
  height: 330px;
}

.storsArrow {
  top: 0;
}

.content:not(:empty) {
  background: $green-light;
  border-radius: 40px;
  padding: 40px 15px;
  margin-top: 70px;

  @media (min-width: $lg) {
    padding: 50px 40px;
  }
}

.sectionName {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}

.sectionLink {
  font-weight: bold;
  font-size: 36px;
  line-height: 34px;
  text-transform: uppercase;
  border-bottom: 2px solid $yellow;
  padding-bottom: 5px;

  &:hover {
    border-bottom: 2px solid $green;
  }
}

.about {
  margin-bottom: 70px;
}

.item {
  width: 80% !important;
  padding: 25px 20px;
  color: $font-black;
  background: $green-light;
  border-radius: 8px;
}

.itemTitle {
  font-weight: bold;
  font-size: 22px;
  line-height: 130%;
  color: $green;
  margin: 30px 0 15px;
}

.text {
  font-size: 14px;
  line-height: 150%;
  color: $green-opacity;
}

.storesLink {
  margin: 20px 0 70px;
}

.storeWrap {
  position: relative;
  width: 30.2%;
  margin: 15px;
}

.storeCard {
  position: absolute;
  width: 86%;
  text-align: center;
  background: $white;
  padding: 20px 0;
  border-radius: 8px;

  &:hover {
    z-index: 3;
    box-shadow: 0 4px 25px rgba(6, 98, 59, 0.15);
  }

  &:hover .storeInner {
    height: auto;
  }
}

.storeLogo {
  max-width: 112px;
  height: 112px;
  margin: 0 auto 27px;
}

.logoImg {
  max-width: 100%;
}

.storeInner {
  height: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    margin-top: 25px;
    border-bottom: 2px solid $yellow;
    padding-bottom: 4px;
  }
}

.storeName {
  font-size: 14px;
  line-height: 13px;
  text-transform: uppercase;

  &:hover {
    color: $green;
  }
}

.reviews {
  font-size: 12px;
  line-height: 11px;
  color: $green-opacity;
  margin: 10px 0;
}

.unrounded {
  border-radius: 40px 40px 0 0;
  padding-bottom: 100px;
}

.mobile {
  display: none;
}

@media (max-width: 600px) {
  .ad {
    flex-direction: column;
    align-items: center;
  }

  .banner {
    margin: 20px 0 0;
    width: 100%;
  }

  .searchMain {
    width: calc(100% - 60px);
  }
}

@media (max-width: 768px) {
  .desktop {
    display: none;
  }

  .mobile {
    display: block;
  }

  .sectionName {
    justify-content: center;
  }

  .header {
    border-radius: 0 0 16px 16px;
  }

  .headerText {
    font-size: 20px;
  }

  .slick-dots {
    padding: 0;
  }

  .storeInner {
    height: auto;
  }
}

.benefits {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 50px;
}

.benefit {
  box-sizing: border-box;
  width: 100%;
  padding: 14px;
  text-align: center;

  svg {
    height: 50px;
    margin-bottom: 32px;
  }

  @media (min-width: $sm) {
    width: 50%;
  }

  @media (min-width: $lg) {
    width: 25%;
  }
}

.benefitTitle {
  margin: 0 0 16px;
  font-size: 18px;
}

.benefitText {
  margin: 0;
  font-size: 14px;
  line-height: 150%;
  color: $gray;
}

.subscribe {
  margin-top: 36px;
}
