@import 'src/styles/colors_v2';
@import 'src/styles/colors';
@import 'src/styles/breakpoints';
@import 'src/styles/z_index';

.ourFarmersWrapper {
  padding-bottom: 60px;
  position: relative;
  margin-top: 32px;

  @media (min-width: $sm) {
    margin-top: 80px;
  }
}
.ourFarmersHeader {
  display: flex;
  align-items: center;

  @media (min-width: $sm) {
    justify-content: space-between;
  }
}

.headerWrapper {
  position: absolute;
  left: 0;
  right: 0;
}

.headerLeftContainer {
  display: flex;
  flex-grow: 1;
  align-items: flex-end;
  justify-content: space-between;

  @media (min-width: $sm) {
    justify-content: start;
  }
}

.ourFarmersHeaderTitle {
  font-size: 28px;
  font-weight: 700;
  line-height: 110%;

  @media (min-width: $sm) {
    font-size: 48px;
  }
}

.ourFarmersHeaderSubTitle {
  font-weight: 400;
  font-size: 12px;
  color: $white-aluminum;
  line-height: 140%;
  margin-bottom: 3px;
  position: relative;
  z-index: $z-100;

  @media (min-width: $sm) {
    font-weight: 500;
    font-size: 18px;
    margin-left: 24px;
  }
}

.HeaderRightContainer {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0px;
  width: 100%;

  @media (min-width: $sm) {
    position: relative;
    max-width: 270px;
  }
}

.ourFarmersHeaderButton {
  border-radius: 106.071px;
  border: 2px solid $gainsborough;
  background-repeat: no-repeat;
}

.OurFarmersButtonBack {
  padding: 20.5px;
  background-image: url('../../../../public/icons2/chevron-left.svg');
  max-width: 44px;
  width: 100%;
  background-position: center center;

  @media (min-width: $sm) {
    padding: 21.5px;
    max-width: 48px;
  }
}

.OurFarmersButtonNext {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
  white-space: nowrap;
  padding: 9.2px 0;
  margin-left: 8px;
  width: 100%;

  @media (min-width: $sm) {
    font-size: 16px;
    max-width: 214px;
    padding: 10.5px 36px 10.5px 24px;
  }
}

.ourFarmersMainContainer {
  padding: 24px 16px;
  background-color: $white-transparent;
  border-radius: 32px;
  margin-top: 24px;
  height: 408px;

  @media (min-width: $sm) {
    padding: 40px 32px;
    height: 505px;
  }
}

.ourFarmersMainTitleBlock {
  display: flex;
  justify-content: space-between;
}

.ourFarmersNameWrapper {
  display: flex;
  flex-direction: column;

  @media (min-width: $sm) {
    align-items: center;
    flex-direction: row;
  }
}

.ourFarmersFarmerName {
  font-size: 18px;
  line-height: 42px;
  font-weight: 500;

  @media (min-width: $sm) {
    font-size: 32px;
  }
}

.ourFarmersReviews {
  display: flex;
  font-size: 14px;
  line-height: 16px;
  max-width: 52px;
  background-color: $white;
  padding: 8px 10px 8px 2px;
  border-radius: 100px;

  img {
    padding: 0 5px 0 8px;
  }

  @media (min-width: $sm) {
    margin-left: 12px;
  }
}

.ourFarmersLikeButton {
  background-color: $white;
  border-radius: 90px;
  max-width: 32px;
  max-height: 32px;
  width: 100%;

  @media (min-width: $sm) {
    max-width: 40px;
    min-height: 40px;
  }
}

.OurFarmersCatalog {
  display: flex;
  margin-top: 16px;
  overflow: auto;
  align-items: center;
  white-space: nowrap;
}

.OurFarmersCatalogItem {
  margin-right: 24px;
  line-height: 130%;
  font-size: 12px;

  &:last-child {
    margin-right: 0;
  }

  @media (min-width: $sm) {
    font-size: 16px;
  }
}

.OurFarmersCatalogItemActive {
  line-height: 130%;
  color: $white;
  padding: 6px 18px;
  background-color: $dark-bluishGreen;
  border-radius: 90px;
  font-size: 12px;
  max-height: 29px;
  min-width: 194px;

  @media (min-width: $sm) {
    padding: 12px 18px;
    font-size: 16px;
    max-height: 45px;
  }
}

.OurFarmersProduct {
  text-align: center;
  display: flex;
  position: relative;
  z-index: 1;
  margin: 16px -8px 0 -8px;
  overflow-x: auto;

  @media (max-width: 1024px) {
  }

  @media (max-width: $sm) {
    overflow-x: auto;
    margin: 24px -8px 0 -8px;
  }
}

.ProductCardIndents {
  position: relative;
  z-index: 10;
  padding: 0 8px;
  width: 55%;
  box-sizing: border-box;
  min-width: 268px;
  max-width: 268px;

  @media (max-width: 1024px) {
    min-width: 242px;
    margin: 0 5px;
    // max-width: 268px;
  }

  @media (max-width: $sm) {
    min-width: 160px;
  }
}

.farmerCard {
  padding: 0 8px;
  width: 55%;
  box-sizing: border-box;
  min-width: 268px;

  @media (max-width: 1024px) {
    min-width: 242px;
    margin: 0 5px;
  }

  @media (max-width: $sm) {
    min-width: 160px;
  }
}

.personalAreaTitleBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (min-width: $sm) {
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.farmersPersonalAreaBlock {
  display: flex;
  align-items: center;
}

.farmersInfoBlock {
  margin-left: 12px;
}

.farmerName {
  font-size: 18px;

  @media (min-width: $sm) {
  }
}

.farmerNameContainer {
  display: flex;
  align-items: center;
}

.farmerGeolocation {
  line-height: 20px;
  font-size: 14px;
  color: $telegray;
}

.deliveryTermsContainer {
  display: flex;
  margin-top: 12px;
  font-size: 12px;
  line-height: 17px;
  overflow: auto;
}

.deliveryTermsItem {
  padding: 4px 8px;
  background-color: $periwinkle-crayola;
  margin-right: 8px;
  white-space: nowrap;
  border-radius: 8px;
}

.personalAreaWrapper {
  margin-top: 16px;

  @media (min-width: $sm) {
    margin-top: 24px;
  }
}

.farmerFoto {
  max-width: 44px;
  max-height: 44px;

  @media (min-width: $sm) {
    max-width: 40px;
    max-height: 40px;
  }
}

.swiperWrapper {
  :global {
    .swiper {
    }

    .swiper-button-next {
      top: 550px;
      width: 85%;
      margin-top: 0;
      z-index: 101;
      left: 50px;
      right: 20px;

      @media (min-width: $sm) {
        width: 100px;
        left: auto;
        top: 6px;
        right: 60px;
      }
    }

    .swiper-button-prev {
      z-index: 101;
      margin-top: 0;
      right: 232px;
      top: 550px;

      @media (min-width: $sm) {
        left: auto;
        right: 232px;
        top: 6px;
      }
    }

    .swiper-button-next:after {
      display: flex;
      justify-content: center;
      padding: 13px 50px;
      max-height: 46px;
      border-radius: 106px;
      border: 2px solid $gainsborough;
      font-weight: 500;
      white-space: nowrap;
      font-family: 'Roboto', sans-serif;
      content: 'Следующий фермер >';
      font-size: 16px;
      color: black;
      width: 100%;
    }

    .swiper-button-prev:after {
      padding: 14.5px 18px;

      max-height: 46px;
      border-radius: 106px;

      border: 2px solid $gainsborough;
      font-weight: 500;
      white-space: nowrap;
      font-family: 'swiper-icons';
      content: 'prev';
      font-size: 14px;
      font-weight: bold;

      color: black;
    }
  }
}

.swiperBlock {
  padding: 20px 0 100px 0;

  @media (min-width: $sm) {
    padding-top: 50px;
  }
}
