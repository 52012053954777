@import 'src/styles/colors';

.wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;

  :global {
    .disabled a,
    .disabled:hover a {
      background: $green-shift;
      color: $green;
      cursor: default;
      opacity: 0.8;
    }
  }
}

.moreBtn {
  margin-bottom: 20px;
}

.contain {
  display: flex;
}

.item {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
  width: 50px;
  height: 50px;
  border-radius: 5px;
  background: $green-shift;
  color: $green;
  font-weight: bold;
  font-size: 15px;
  line-height: 14px;
  outline: none;

  svg {
    fill: $green;
  }

  &:hover {
    background: darken($green-shift, 10);
    cursor: pointer;
  }
}

.breakItem svg {
  margin-top: 10px;
}

.active {
  background: $green-pastel;
  color: $white;
}
