@import 'src/styles/colors_v2';
@import 'src/styles/colors';
@import 'src/styles/breakpoints';
@import 'src/styles/z_index';

.bestOfferWrapper {
  background-image: url('../../../public/icons2/background__best-offer.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  padding: 180px 0;
  width: 100%;
}

.bestOfferContainer {
  position: relative;
  z-index: $z-0;
  display: flex;
  overflow: auto;
  margin: 0 -8px;
  text-align: center;
  padding: 5px 0;

  :global {
    .quantity-selection-text {
      white-space: nowrap;

      @media (max-width: $sm) {
        white-space: wrap;
      }
    }

    .product-cart__item-top {
      font-size: 16px;
    }

    .product-cart-past-price {
      font-size: 16px;
      margin-bottom: -15px;

      @media (min-width: $sm) {
        margin-bottom: -25px;
      }
    }

    .product-card-discount-price {
      font-size: 24px;
    }

    .svg-past-price-rubl {
    }

    .svg-discount-rubl {
      top: -3px;
      width: 28px;

      @media (min-width: $sm) {
        top: -7px;
        width: 30px;
      }
    }
  }
}

.BestOfferTitle {
  margin-bottom: 8px;
  font-size: 28px;
  font-weight: 700;
  line-height: 110%;
  color: $white;

  @media (min-width: $sm) {
    font-size: 48px;
  }
}

.ProductCardIndents {
  padding: 0 8px;
  width: 50%;
  box-sizing: border-box;

  @media (max-width: 1024px) {
    min-width: 242px;
  }

  @media (max-width: $sm) {
    min-width: 160px;
  }

  @media (min-width: $sm) {
    width: 25%;
    padding: 0 4px;
  }
}
